.SIDENAV {
  min-height: 100vh;
}

.SIDENAV .ant-layout-sider-children {
  background-color: #12284A !important;
}

.ant-layout-sider {
  position: fixed !important;
  bottom: 0px !important;
  top: 0px !important;
}

.SIDENAV .ant-menu-item:hover {
  background: #183561 !important;
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  line-height: 27px !important;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #FFFFFF !important;
}