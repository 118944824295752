.card {
    width: 141px; /* Fixed width for each card */
    height: 90px; /* Fixed height */
    margin-top: 2rem;
    flex-shrink: 0;
    border-radius: 9px;
    border: 1px solid var(--swirepay-black-gray-2, #EBEBEB);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    text-align: center;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.3);
}

.card img {
    max-width: 80%;
    height: auto;
    margin: 0 auto;
    margin-top: 10px;
}

.main_div {
    display: flex;
    gap: 10px;
    margin: 0 auto;
    flex-wrap: wrap;
    max-width: 600px; 
    width: 100%; 
}
@media (max-width: 768px) {
    .main_div {
        max-width: 100%;
    }
}

@media (max-width: 480px) {
    .card {
        width: 100%; 
    }
}
