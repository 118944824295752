@import '~bootstrap/dist/css/bootstrap.css';
@import "slider";
@import "table";
@import "modal";
@import "menu";
@import "login";
@import "sidenav";
@import "icon";
@import "card";
@import "header";
@import "notification";
@import "others";
@import "timelinebar";
@import "steps";
@import "panel";

/* -------------------------------------------override css for table checkbox width--------------------------------- */
.custom-table .ant-table-selection-column {
    width: 10% !important;
      }