.ant-card-head-title {
  font-size: 14px !important;
}

.ant-card-body {
  padding-top: 8px !important;
}

.text-wrap {
  word-wrap: break-word;
}

.bank-accounts {
  height: 100px;
  overflow-y: scroll;
}