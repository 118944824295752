/* General */
.scrollable-content::-webkit-scrollbar {
    width: 8px;
}

.scrollable-content::-webkit-scrollbar-thumb {
    background: #888; /* Scrollbar thumb color */
    border-radius: 4px; /* Rounded corners */
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
    background: #000; /* Fixed incorrect hex code */
}

.scrollable-content::-webkit-scrollbar-track {
    background: linear-gradient(to bottom, var(--primary-color));
}

.background {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom, transparent, var(--primary-color));
}

/**/
.fixed-top-section {
    background: #1C1C1C;
    color: #fff;
    padding: 7px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    height: 5%;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.support-list {
    position: absolute;
    top: 30px;
    right: 0;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px 0;
    width: 250px;
    z-index: 10;
}
.support-item {
    padding: 10px 20px;
    cursor: pointer;
    color: #000;
    font-size: 16px;
    white-space: nowrap;
    line-height: 20px;
}
.support-separator {
    height: 1px;
    background-color: #e0e0e0;
    margin: 5px 0;
}

.error-notification {
    background-color: rgba(255, 255, 255, 0.9);
    border-left: 4px solid #ff4d4f;
    color: #721c24;
    font-weight: 600;
    font-size: 20px;
    padding: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    gap: 8px;
}

.fixed-logo-section {
    background: rgba(255, 255, 255, 0.7);
    padding: 20px;
    height: 12%;
    display: flex;
    align-items: center;
    position: fixed;
    top: 5%;
    width: 100%;
    z-index: 9;
}

.loader {
    width: 90px;
    height: 90px;
    border: 10px solid rgba(0, 0, 0, 0.2);
    border-top: 10px solid #007BFF;
    border-radius: 50%;
}

.loader-message {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    margin-top: 20px;
    color: #131313;
}

.gap-8 {
    gap: 8px;
}
.gap-20 {
    gap: 15px;
}

.content-section {
    margin: 0;
    top: 17%;
    bottom: 11%;
    padding: 10px;
    display: flex;
    position: fixed;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
}

.product-section {
    top: 17%;
    bottom: 11%;
    padding: 20px;
    position: fixed;
    width: 100%;
    border-radius: 8px;
    overflow-y: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.product-screen {
    background: rgba(255, 255, 255, 0.7);
    width: 50%;
    margin: auto;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
}

.drop-down-menu {
    background: none;
    border: none;
    color: #000000;
    cursor: pointer;
    font-size: 14px;
    padding: 0;
    margin-bottom: 3px;
}

.discount-badge {
    display: inline-block;
    background-color: #00B04A;
    border-radius: 6px 0 0 6px;
    padding: 3px 6px;
    margin-left: 5px;
    font-size: 12px;
    color: #FFF;
    font-weight: 400;
    position: relative;
}
.discount-badge::before,
.discount-badge::after {
    content: "";
    position: absolute;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    display: inline-block;
}
.discount-badge::before {
    top: 0;
    border-width: 18px 15px 0 0;
    border-color: #00B04A transparent transparent transparent;
}
.discount-badge::after {
    bottom: 0;
    border-width: 0 15px 18px 0;
    border-color: transparent transparent #00B04A transparent;
}

.tip-button {
    background: #FFF;
    border: 1px solid #ACB1C6;
    border-radius: 6px;
    min-width: 60px;
    text-align: center;
}

.stepper {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
}

.back-button {
    display: flex;
    flex: 0.5;
    align-items: center;
    cursor: pointer;
    margin-left: 3px;
}

.input-box {
    border: 2px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

.banner-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
    overflow: hidden;
}

.additional-info {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
    color: #676767;
}

.details {
    flex: 1;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 1%;
    width: 35%;
    color: #000000;
    font-family: Arial, sans-serif;
    overflow: hidden;
}

.add-button {
    background: white;
    border: 1px solid #000000;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}

.increase-decrease-button {
    border: none;
    background: #FFFFFF;
    color: #000000;
    padding: 8px 14px;
    font-size: 18px;
    cursor: pointer;
}

.input-item {
    width: 40px;
    text-align: center;
    border: none;
    font-size: 16px;
    font-weight: bold;
}

.fixed-footer {
    background-color: var(--primary-color);
    padding: 20px 0;
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 11%;
    z-index: 10;
}

.book-now-btn {
    padding: 10px 90px;
    background-color: #fff;
    color: #000000;
    border: none;
    font-size: 18px;
    font-weight: bold;
    border-radius: 20px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
    transition: background-color 0.3s ease;
}

.book-now-btn:hover {
    background-color: #f0f0f0;
}
