.LOGIN input {
  border-radius: 5px;
  height: 40px;
}

.LOGIN .ant-select-selection {
  border-radius: 5px;
  height: 40px;
  padding-top: 3px;
}

.link-style {
  color: #F7F7F7 !important;
}

.link-style:hover {
  color: #ffffff !important;
}
