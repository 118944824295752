html,
body {
  min-width: 100%;
  margin: 0 auto;
  background-color: #e3e8ee;
}

a {
  &:hover {
    color: #40ACFF !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.OVERLAY {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.2);
}

.ant-calendar-picker {
  width: 100%;
}

.numFormate {
  border: transparent !important;
  width: 60%;
  background-color: transparent !important;
  font-weight: 600;
}

.InputnumFormate {
  border: 1px solid #d9d9d9 !important;
  padding: 5px 10px;
  width: 100%;
  border-radius: 5px;
}

.InputnumFormate:hover {
  border: 1px solid #57dff7 !important;
}

.InputnumFormate:focus {
  outline: none !important;
  box-shadow: 0 0 3px #719ECE;
  border: 1px solid #57dff7 !important;
}

.upload-card {
  padding: 10% !important;
  width: 100% !important;
}

.pay-button>a:hover {
  color: #fff !important;
}

.payment-page-form-elements {
  max-height: 300px;
  overflow-y: auto;
  max-width: 100%;
}

.resize {
  resize: none !important;
}

section .ant-layout {
  margin-left: 240px;
  // min-height: 1250px;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
.ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
  padding: 0px !important;
  border-bottom: 1px solid #C4C4C4;
}
.tab-header .ant-tabs-nav .ant-tabs-tab  {
  margin: 0px 10px 0 0 !important;
}
.ant-tabs .ant-tabs-left-bar {
  padding-top: 20px !important;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  padding-left: 10px !important;
}

.ant-tabs-tab {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 10px 10px 0px 0px !important;
}

.ant-tabs-tab-active {
  background-color: #214885 !important;
  color: #fff !important;
  border-radius: 10px 10px 0px 0px !important;
}

.service-btn {
  margin-left: 28% !important;
}

.global-drag-line {
  border-color: #FFFFFF !important;
}

.InputnumFormate {
  background-color: #FFFFFF !important;
}

.pinSet {
  max-width: 550px;
  margin: 25px auto 0;

  input {
    margin: 0 5px;
    text-align: center;
    line-height: 32px;
    border: solid 1px #ccc;
    box-shadow: 0 0 5px #ccc inset;
    outline: none;
    width: 32px !important;
    transition: all .2s ease-in-out;
    border-radius: 3px;

    &:focus {
      border-color: #40A9FF;
      box-shadow: 0 0 5px #40A9FF inset;
    }
  }
}

.css-1wy0on6 {
  display: none !important;
}

.catalog-list {
  scrollbar-color: rgba(0, 0, 0, 0) #65748C;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.5rem;
    margin-left: 5px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.123);
    border-radius: 10px;
    background: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.274);
    background: #65748C;
  }
}

.ant-drawer {
  z-index: 0 !important; /* Set your desired z-index value */
}

.no-break-word {
  word-break: normal !important;
}
.richTextFormatting {
  .ql-editor {
    height: 200px !important;
    background-color: #fff !important;
  }
}

iframe {
  width: 100%;
  height: 100vh;
  border: 0;
  overflow: hidden;
}

.react-flow {
  height: 900px !important;
  background-color: #fff !important;
}

.react-flow__edge {
  stroke-dasharray: 5;
  animation: dashdraw .5s linear infinite;
}

.react-flow__edge-path {
  stroke: #b1b1b7;
  stroke-width: 2;
}

.react-flow__handle {
  display: none !important;
}

// .ActionNode_actionNode__2-FMq {
//   border: 1px transparent !important;
// }

.react-flow__panel {
  display: none !important;
}

.line {
  display: none !important;
}

.react-flow__resize-control.handle.left {
  display: none !important;
}

.react-flow__resize-control.handle.top.right {
  display: none !important;
}

.ant-select-selection--multiple {
  min-height: 100px !important;
}

.ant-select-selection--multiple > ul > li, .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  border-radius: 20px !important;
}

.ant-modal-title {
  font-weight: 600 !important;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-nav-wrap {
  height: 700px !important;
  overflow-y: scroll !important;
}

.ant-table-body {
  overflow-x: auto !important;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 50px;
  height: 35px;
  left: 10px;
  top: 10px;
  box-shadow: 2px 0px 1px 2px #F3F3F3 !important;
  border-radius: 0px 10px 10px 0px !important;
  padding: 8px;
}

// @media only screen and (max-width: 479px) {
//   .bm-burger-button {
//     position: fixed !important;
//     top: 170px !important;
//     background-color: white;
//   }
// }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  // background: #373a47;
  background: #000000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  z-index: 99;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  background: #12284a !important;
}

.inputStyle {
  width: 3rem!important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,.3)
}
