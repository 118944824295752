.categoryname_panerheader{
    color: var(--swirepay-black-gray-13, #292929);
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: uppercase;
border-radius: 2px;
border: 1px solid var(--swirepay-black-gray-2, #EBEBEB);
background: var(--swirepay-black-gray-1, #F5F5F5);
}
.item_box{
border-radius: 2px;
border: 1px solid var(--swirepay-black-gray-5, #B3B3B3);
background: var(--Swirepay-White-1, #FFF);
min-height: 100px;
width: 200px;
margin-right: 10px;
display: flex;
justify-content: space-between;
}
.individual_itemname{
    color: #303030;
font-family: "Open Sans";
font-size: 13px;
font-style: normal;
font-weight: 600;
margin-bottom: 5px;
margin: 2px;
line-height: 229.182%; /* 29.794px */
}
.individual_itemprice{
    color: #303030;
font-family: "Open Sans";
font-size: 15px;
font-style: normal;
margin: 2px;
font-weight: 400;
line-height: 229.182%; /* 34.377px */
}
.oninename_box{
    border-radius: 4px;
    border: 1px solid var(--swirepay-black-gray-5, #B3B3B3);
    background: var(--Swirepay-White-1, #FFF);
    height: 37px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    width: 300px;
    margin-right: 10px;
}
