/* ANT MODAL OVERRIDES */
.ant-modal-body {
  padding: 8px 0 0 !important;
  background-color: #f2f5f8 !important;
}

.ant-modal-footer {
  border: none !important;
  background-color: #f2f5f8 !important;
  padding: 16px 24px !important;
}
