.HEADER .ACCOUNT-SELECTION .ant-select-focused .ant-select-selection,
.HEADER .ACCOUNT-SELECTION .ant-select-selection,
.HEADER .ACCOUNT_SELECTION .ant-select-selection:focus,
.HEADER .ACCOUNT_SELECTION .ant-select-selection:active,
.HEADER .ACCOUNT_SELECTION .ant-select-open .ant-select-selection {
  border: none;
  box-shadow: none;
}

.HEADER .ant-select-selection-selected-value {
  font-weight: bold;
}

.HEADER .ant-select-arrow {
  color: black;
}

.HEADER .ant-avatar-lg {
  width: 35px;
  height: 35px;
  line-height: 34px;
}

.HEADER .ant-avatar-circle {
  border: 1px solid #2D87CA;
}

.HEADER .ant-avatar-string {
  color: #515967;
}
