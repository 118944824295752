
.bar-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left:0px;  
  margin-top: 15px;
}

.middle {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left {
  width: 16%;
  line-height: 14px;
}

.right {
  width: 45%;
  line-height: 14px;
}

.icon {
  margin: 0px;
  width: 16px;
  height: 16px;
}

.line {
  border-left: 2px solid #AAA;
  height: 100%;
  min-height: 60px;
}

.item {
  display: flex;
}

.invoiceline {
  border-left: 2px solid #00C289;
  height: 100%;
  min-height: 75px;
}

.left1 {
  width: 0%;
  line-height: 14px;
}

.right1 {
  width: 80%;
  line-height: 14px;
  margin-left: 10px;
}
