.ant-steps {
  background: #fff;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}

.paymentPage {
  padding: 24px 100px !important;
}

.subscriptions {
  padding: 24px !important;
}